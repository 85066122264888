import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { getGuestChat } from '../actions';
import { chatTypes } from '../constants/actionTypes';

const ListContainer = styled.div`
  margin: 10px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 6px;
`;

const LeftHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
`;

const Back = styled.div`
  font-weight: 500;
  padding: 6px;
  margin-right: 8px;
  cursor: pointer;
`;

const Header = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #323842ff;
`;

const NewChatIcon = styled.div`
  background: #efb034ff;
  border-radius: 50%;
  height: 36px;
  width: 36px;
  font-size: 26px;
  cursor: pointer;
`;

const ListHeader = styled.div`
  padding: 12px 16px;
  background: #f6f3f3;
  box-sizing: border-box;
  margin: 0px -10px 8px;
  box-shadow: 0px 3px #e6e6e6;
  text-align: left;
`;

const ListItem = styled.div`
  border-bottom: 1px solid #dbcbcb;
  padding: 8px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  cursor: pointer;
`;

const GoToSymbol = styled.span`
  font-weight: 500;
  font-size: 20px;
`;

const ChatList = ({ setShowChat }: { setShowChat: () => void }) => {
  const { weddingId: weddingIdParams } = useParams();
  const dispatch = useDispatch();
  const weddingId =
    useSelector((state: any) => state.chatReducer.wedding)?.id ||
    weddingIdParams;
  const guestList: any = useSelector((state: any) => state.chatReducer.guests);

  const resetWedding = () => {
    dispatch({
      type: chatTypes.SET_WEDDING,
      payload: {},
    });
  };

  return (
    <ListContainer>
      <HeaderContainer>
        <LeftHeader>
          {!weddingIdParams && <Back onClick={resetWedding}>&lt;</Back>}
          <Header>Guest List</Header>
        </LeftHeader>
        {/* <NewChatIcon
          onClick={() => {
            setShowChat();
            dispatch({
              type: chatTypes.SET_SESSION_ID,
            });
          }}
        >
          +
        </NewChatIcon> */}
      </HeaderContainer>
      <ListHeader>Guest Name</ListHeader>
      {guestList?.map((guest: any) => (
        <ListItem
          key={guest?.guestId}
          onClick={() => {
            if (weddingId) {
              getGuestChat(weddingId, guest)(dispatch);
            }
            setShowChat();
          }}
        >
          {guest?.guestName}
          <GoToSymbol>&gt;</GoToSymbol>
        </ListItem>
      ))}
      {guestList?.length === 0 && 'No guests found'}
    </ListContainer>
  );
};

export default ChatList;
